<template>
  <div style="background-color: #fafafa">
    <v-container>
      <div class="coverage">
        <div class="coverage-heading">
          <h1>Cobertura</h1>
          <img :src="UcDark" alt="Logo UC Christus">
        </div>

        <div class="coverage-container">
          <v-layout row wrap>
            <v-card max-width="325">
              <v-img :src="Coverage1" height="250px"></v-img>
              <v-card-title>
                Consultas Psiquiátricas
              </v-card-title>
              <v-card-text>
                Para dar una atención completa a los pacientes 
                se ofrecen treinta (30) consultas psiquiátricas 
                al año.
              </v-card-text> 
              
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage2" height="250px"></v-img>
              <v-card-title> Tratamientos Hospitalarios </v-card-title>
              <v-card-text>
                Para ser atendido en centros hospitalarios de excelencia 
                que formen parte de la <b>Red de Salud UC Christus</b>. 
              </v-card-text>
              
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage3" height="250px"></v-img>
              <v-card-title>
                Tratamientos Ambulatorios
              </v-card-title>
              <v-card-text>
                Recibe acompañamiento por parte del equipo de salud 
                de la <b>Red de Salud UC Christus</b> en esta difícil
                etapa. 
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage4" height="250px"></v-img>
              <v-card-title>
                Radioterapia
              </v-card-title>
              <v-card-text>
                Como parte de un tratamiento completo recibirás 
                apoyo en tu proceso de Radioterapia. 
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage5" height="250px"></v-img>
              <v-card-title> Quimioterapia </v-card-title>
              <v-card-text>
                Ten la tranquilidad de que UC Christus te acompañará 
                en este difícil tratamiento.
              </v-card-text>
             
            </v-card>
          </v-layout>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import UcDark from "@/assets/images/uc_dark.png"

import Coverage1 from "@/assets/images/Coverage1.jpg";
import Coverage2 from "@/assets/images/Coverage2.jpg";
import Coverage3 from "@/assets/images/Coverage3.jpg";
import Coverage4 from "@/assets/images/Coverage4.jpg";
import Coverage5 from "@/assets/images/Coverage5.jpg";

export default {
  name: "Coverage",
  data: () => ({
    Coverage1,
    Coverage2,
    Coverage3,
    Coverage4,
    Coverage5,
    UcDark
  }),
};
</script>

<style lang="scss">
.coverage {
  padding: 4rem 0;

  .coverage-heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3rem;
    h1 {
      text-align: center;
      font-size: 2.5rem;
    }
    img{
      max-width: 90%;
    }
  }
  

  .coverage-container {
    .layout.wrap {
      justify-content: space-around;
    }

    .v-card {
      margin: 1rem;
    }

    .v-card__title {
      font-weight: bold;
      word-break: unset;
      line-height: 1.2;
    }

    .v-card__text {
      color: black !important;
      line-height: 1.2;
    }
  }
}
</style>