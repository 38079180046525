<template>
  <v-app>
    <v-app-bar app color="#FFFFFF" light elevation="0" height="75">
      <router-link :to="{ path: '/' }">
        <div class="d-flex align-center">
          <v-img
            class="shrink mt-1 hidden-sm-and-down"
            contain
            max-height="75"
            :src="Walmart"
            max-width="125"
            style="margin-left: 2rem"
          />
        </div>
      </router-link>

      <v-spacer></v-spacer>

    




    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Walmart from "@/assets/images/Intro.png";

export default {
  components: {
    Footer
  },
  data: () => ({
    Walmart,
  }),
};
</script>