<template>
  <v-container class="benefits-container">
    <div class="benefits">
      <div class="benefit-detail">
        <div class="benefit-img-container seg"> 
          <v-img contain max-height="130" :src="Benefit1" max-width="130" />
        </div>
        <h3>Medicina UC</h3>
        <h4>Excelencia de servicio garantizado en la red UC Christus.</h4>
      </div>
      <div class="benefit-detail">
        <div class="benefit-img-container dig"> 
          <v-img contain max-height="130" :src="Benefit2" max-width="130" />
        </div>
        <h3>100% digital</h3>
        <h4>Contratación inmediata.</h4>
      </div>
      <div class="benefit-detail">
        <div class="benefit-img-container tel"> 
          <v-img contain max-height="130" :src="Benefit3" max-width="130" />
        </div>
        <h3>Telemedicina</h3>
        <h4>Consulta medica gratis a un sólo clic..</h4>
      </div>
      <div class="benefit-detail">
        <div class="benefit-img-container mony"> 
          <v-img contain max-height="130" :src="Benefit4" max-width="130" />
        </div>
        <h3>Sin Deducible</h3>
        <h4>No existe monto de deducible asociado.</h4>
      </div>
      <div class="benefit-detail">
        <div class="benefit-img-container clip"> 
          <v-img contain max-height="130" :src="Benefit5" max-width="130" />
        </div>
        <h3>Flexible</h3>
        <h4>Planes individuales y familiares según tus necesidades.</h4>
      </div>
    </div>
  </v-container>
</template>

<script>
import Benefit1 from "@/assets/images/Benefit1.png";
import Benefit2 from "@/assets/images/Benefit2.png";
import Benefit3 from "@/assets/images/Benefit3.png";
import Benefit4 from "@/assets/images/Benefit4.png";
import Benefit5 from "@/assets/images/Benefit5.png";

export default {
  name: "Benefits",
  data: () => ({
    Benefit1,
    Benefit2,
    Benefit3,
    Benefit4,
    Benefit5
  }),
};
</script>

<style lang="scss">

.benefits {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem 0;
  flex-wrap: wrap;
  justify-content: center;

  .benefit-detail {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      text-align: center;
    }

    h4 {
      text-align: center;
      font-weight: normal;
    }
    .benefit-img-container{
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1%;
      border-radius: 100%;
      
    }
    .clip{
      background-color: #60BA38;
    }
    .mony{
      background-color: #0070B7;
    }
    .tel{
      background-color: #C0001C;
    }
    .dig{
      background-color: #008368;
    }
    .seg{
      background-color: #FF8400;
    }
  }
}
</style>
