<template>
  <div style="background-color: #00213c">
    <div style="background-color: #41b0e7; width: 100%; height: 1rem"></div>
    <v-container style="margin-top: 4rem; margin-bottom: 5rem">
      <v-layout row wrap>
        <div
          class="footer-element footer-img"
          style=""
        >
          <v-img
            class="shrink mt-1 "
            contain
            max-height="100"
            :src="Walmart"
            max-width="175"
          />
        </div>
        <div
          class="footer-element footer-menu"
          style=""
        >
          <div style="height: 100%">
            <ul style="list-style: none; padding-left: 0">
              <li>Seguros</li>
              <li>Cotizador</li>
              <li>Nosotros</li>
              <li>Contacto</li>
              <li>Mis seguros</li>
            </ul>
          </div>
        </div>
        <div class="footer-element footer-contact">
          <div>
            <div style="margin-bottom: 1.5rem">
              <p>Dirección</p>
              <p>Comuna</p>
            </div>

            <div style="margin-bottom: 1.5rem">
              <p>Horario de atención:</p>
              <p>L a V</p>
            </div>

            <div style="margin-bottom: 1.5rem">
              <p>
                Teléfono:
                <a style="color: #41b0e7" href="#"
                  ></a
                >
              </p>
            </div>

            <div style="margin-bottom: 1.5rem">
              <p>
                Email:
                <a
                  style="color: #41b0e7"
                  href="#"
                  ></a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="footer-element footer-rrss" style="">
            <div style="">Síguenos en</div>
            <div >
              <v-btn class="ma-0" text icon color="secondary" href="#" target="_blank">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-btn class="ma-0" text icon color="secondary" href="#" target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn class="ma-0" text icon color="secondary" href="#" target="_blank">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>


            </div>
              Este riesgo es tomado por         
              <v-img
                class="shrink mt-1"
                max-height="100"
                :src="LogoSB"
                max-width="175"
              />
              <v-img
                class="shrink mt-1"
                max-height="100"
                :src="UcLight"
                max-width="175"
              />
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Walmart from "@/assets/images/Intro.png";
import LogoSB from "@/assets/images/SB_logo.png";
import UcLight from "@/assets/images/uc_light.png"

export default {
  name: "Footer",
  data: () => ({
    Walmart,
    LogoSB,
    UcLight
  }),
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");
.footer-menu, .footer-img{
  border-right: 1px solid #41b0e7; min-width: 200px
}
.footer-contact{
  border-right: 1px solid #41b0e7; min-width: 300px
}
.footer-rrss{
  min-width: 150px;
  flex-direction: column;
  align-items: flex-start;
  img{
    margin-bottom: 10px;
  }
}
.footer-element {
  color: white;
  min-height: 200px;
  margin: 1rem;
  display: flex;
  align-items: flex-start;

  li {
    margin-bottom: 1rem;
  }

  p {
    line-height: 1;
    margin-bottom: 0.5rem;
  }
}

.layout.wrap {
  justify-content: space-around;
}
@media screen and (max-width: 425px) {
  .footer-contact{
    border-top: none !important;
  }
  .footer-img, .footer-menu{
    width: 100%;
    max-width: none !important;
    border-right: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer-img{
    height: 10vh;
  }
}
@media screen and (max-width: 800px) {
  .footer-contact{
    width: 100%;
    padding-top: 10px;
    min-width: none;
    max-width: none !important;
    border-top: 1px solid #41b0e7;
    border-right: none;
    text-align: center;
    div{
      width: 100%;
      div{
        width: 100%;
      }
    }
  }
  .footer-img, .footer-menu{
    min-width: 40% !important;
    max-width: none !important;
  }
  .footer-rrss{
    border-top: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .footer-rrss{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #41b0e7;
  }
  .footer-contact, .footer-img, .footer-menu{
    max-width: 20%;
    min-width: none;
  }
}
</style>
